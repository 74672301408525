// eslint-disable-next-line no-restricted-imports
import { useQueryParams as originalUseQueryParams } from 'use-query-params';
import { useMemo } from 'react';
import removeUndefinedEntries from '../utils/removeUndefinedEntries';

// stupid use-queryparam lib still includes keys that aren't even in the URL. including these in the url again confuses the backend.
// ... so omit keys without values.
const useQueryParams: typeof originalUseQueryParams = config => {
    const [params, setParams] = originalUseQueryParams(config);

    const paramsWithoutUndefinedValues = useMemo(() => removeUndefinedEntries(params), [params]);

    return [paramsWithoutUndefinedValues, setParams];
};

export default useQueryParams;
